<template>
	<div class="identity">
		<div class="identity-inner">
			<div class="identity-inner-title">
				请选择你的的身份
			</div>
			<div class="identity-inner-card">
				<div @click="identitySelect('T')" class="inner-card-item inner-card-item-T">
					<span class="inner-card-cn">学院老师</span>
					<span class="inner-card-en">Teacher</span>
				</div>
				<div @click="identitySelect('C')" class="inner-card-item inner-card-item-C">
					<span class="inner-card-cn">勤工中心</span>
					<span class="inner-card-en">Center</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import storage from 'store'
	import {
		getConfigCode
	} from "@/api/mobile/user";
	export default {
		data() {
			return {}
		},
		methods: {
			identitySelect(type) {
				console.log(type)
				storage.set("identityType",type)
				if(type=='T'){
					getConfigCode().then(res=>{
						console.log(res,'resres')
						if(res.code == 0){
							var pathUrl= "http://www.57qingong.zjut.edu.cn/#/pc/activity/index"
							const uri = encodeURIComponent(pathUrl)
							const REDIRECT_URI =res.data.baseUrl+'/cas/oauth2.0/authorize?response_type=code&client_id='+res.data.appKey+'&redirect_uri='+uri
							location.href = REDIRECT_URI
						}
					})
				}else{
					this.$router.push({
						path: `/pc/user/login?identityPc=${type}`
					})
				}
				// this.$router.push({
				// 	path: `/pc/user/login?identityPc=${type}`
				// })
			}
		}
	}
</script>

<style lang="scss" scoped>
	.identity {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.identity-inner {
			display: flex;
			flex-direction: column;

			.identity-inner-title {
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 28px;
				color: #303133;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.identity-inner-card {
				display: flex;
				flex-direction: column;

				.inner-card-item-T {
					background-image: url("../../../assets/images/pc/user/bg1.png");
					margin: 48px 0 20px 0;
				}

				.inner-card-item-C {
					background-image: url("../../../assets/images/pc/user/bg2.png");
				}

				.inner-card-item {
					width: 287px;
					height: 110px;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					display: flex;
					flex-direction: column;
					cursor: pointer;

					.inner-card-cn {
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: bold;
						line-height: 22px;
						color: #FFFFFF;
						margin: 30px 0 8px 24px;
					}

					.inner-card-en {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0px;
						color: #FFFFFF;
						opacity: 0.6;
						margin-left: 24px;
					}
				}
			}
		}
	}
</style>
